import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  redirect(e) {
    let new_location = e.detail.detail.value;
    if (new_location) {
      window.location.href = new_location;
    }
  }
}
